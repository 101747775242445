export const contactFormInquaryType = {
    data() {
        return {
            inquaryType: [
                'None',
                'Famous artist',
                'Promoute brand/product',
                'Partnership',
                'Other'
            ]
        }
    }
};