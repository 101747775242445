<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container">
            <h2 class="h2 mt-4 mb-3">Contact requests</h2>

            <b-table
                id="contact_forms"
                :items="list"
                :fields="tableFields"
                :per-page="10"
                :current-page="currentPage">
                <template v-slot:cell(created_at)="data">
                    {{ convertBrouserTZDateOnly(data.item.created_at) }}
                </template>
                <template v-slot:cell(name)="data">
                    {{ data.item.name }}
                </template>
                <template v-slot:cell(email)="data">
                    {{ data.item.email }}
                </template>
                <template v-slot:cell(controls)="data">
                    <button v-on:click="onDeleteClick(data.item.id)" class="btn btn-danger float-right">Delete</button>
                    <button v-on:click="$refs.fullTextPopup.show(data.item.text)" class="btn btn-primary mr-1 float-right">Detail</button>
                </template>
            </b-table>
            <div class="col-12">
                <b-pagination
                    align="center"
                    v-model="currentPage"
                    :total-rows="list.length"
                    :per-page="10"
                ></b-pagination>
            </div>
        </div>
        <full-text-popup ref="fullTextPopup" />
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import fullTextPopup from './FormTextPopup';
import { contactFormInquaryType } from './../../mixins/contact-form-inquary-type.mixin';
import { timeZone } from './../../mixins/time-zone.mixin';

export default {
    mixins: [contactFormInquaryType, timeZone],
    comments: { fullTextPopup },
    data() {
        return {
            isError: false,
            currentPage: 1,
            list: [],
            tableFields: [
                { key: 'created_at', label: "Date", sortable: true },
                { key: 'name', label: "Name" },
                { key: 'email', label: "Email" },
                { key: 'controls', label: "" }
            ]
        }
    },
    components: {
        navAdmin,
        fullTextPopup
    },
    methods: {
        get() {
            this.$http({ url: 'emails', method: 'GET'}).then(res => this.list = res.data.data);
        }, 
        onDeleteClick(id) {

            const isConfirmed = confirm("Are you sure?");

            const success = () => {
                this.get();
            };

            const error = () => this.isError = true;

            if (isConfirmed) {
                this.$http({ url: `emails/${id}`, method: 'DELETE'}).then(success, error);
            }
        }
    },
    mounted() {
        this.get();
    }
}
</script>